import React from "react";
import { withRouter } from "react-router";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const Flows = ({ flows, onFlowClick }) => {
  return (
    <div>
      <h3 style={{ marginBottom: '16px', fontSize: '18px' }}>Choose a flow</h3>
      <List component="nav">
        {flows.map((flow) => {
          return (
            <ListItem
                style={{ padding: 0 }}
              key={flow.uuid}
              onClick={() => onFlowClick(flow.uuid)}
              button
            >
              <ListItemText primary={flow.name} />
              <ListItemIcon>
                <ArrowForwardIosIcon />
              </ListItemIcon>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
};

export default withRouter(Flows);
