import { useState, useEffect } from 'react';
import { TextareaAutosize } from "@material-ui/core";
import * as Utils from "../../utils/utils";
import { marked } from "marked";
import DOMPurify from "dompurify";

const FlexTextArea = ({ view, onUpdateData, userInputs }) => {
  const [inputValue, setInputValue] = useState('');
  const { data, variable, style = {} } = view;
  const { value, placeholder } = data;
  const { commonStyle = {} } = style;

  useEffect(() => {
    setInputValue(value || userInputs[variable.key] || '');
    onUpdateData(variable.key, value || userInputs[variable.key] || '');
  }, []);

  const handleChange = (e) => {
    let newValue = e.target.value === '' ? null : e.target.value;
    setInputValue(e.target.value);
    onUpdateData(variable.key, newValue);
  };

  const renderMarkdown = (markdownText) => {
    const customRenderer = new marked.Renderer();
    customRenderer.paragraph = (text) => `<p>${text}</p><p></p>`;

    const rawMarkup = marked(markdownText.trim(), { renderer: customRenderer, sanitize: true });
    const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
    return { __html: sanitizedMarkup };
  };

  return (
    <>
      <TextareaAutosize
        value={inputValue}
        aria-label="minimum height"
        style={{ width: "100%", ...Utils.styleConverter(commonStyle), ...style?.platformStyle?.web?.style }}
        rowsMin={3}
        onChange={(e) => handleChange(e)}
        placeholder={placeholder}
      />
      <div
        dangerouslySetInnerHTML={renderMarkdown(inputValue)}
        data-markdown-output
        style={{ display: "none" }}
      />
    </>
  );
};

export default FlexTextArea;
//
