import {useState} from "react";
import { Button, TextareaAutosize } from "@material-ui/core";
import { Link } from "@material-ui/core";
import * as Utils from "../../utils/utils";
import queryString from "query-string";
import axios from "axios";
import { useLocation } from "react-router-dom";

const useQuery = () => {
	const { search } = useLocation();
	return search;
};

const FlexButton = ({ view, index, onActionClick, isValid }) => {
	const { data, style, action } = view;
	const { commonStyle = {} } = style;
	const [isCopy, setIsCopy] = useState(false);
	const query = useQuery()

	const disabled = action?.actionType === "submit" && !isValid;
	const disabledStyles = disabled ? { ...style?.commonAction?.onDisable } : {};

	const onClickAction = async () => {
		if (action?.actionType === "copy") {
			try {
				const { variable } = view;
				const textAreas = document.querySelectorAll('div[data-markdown-output="true"]');
				const textAreaIndex = textAreas.length > 1 ? parseInt(variable.value) : 0;
				const textArea = textAreas[textAreaIndex].innerHTML;

				if (textArea) {
					function removeLastEmptyParagraph(htmlString) {
						let parser = new DOMParser();
						let doc = parser.parseFromString(htmlString, 'text/html');
						let paragraphs = doc.querySelectorAll('p');
					
						if (paragraphs.length > 0) {
							let lastParagraph = paragraphs[paragraphs.length - 1];
					
							if (lastParagraph.innerHTML.trim() === '') {
								lastParagraph.parentNode.removeChild(lastParagraph);
							}
						}
					
						return doc.body.innerHTML;
					}

					const textAreaFormatted = removeLastEmptyParagraph(textArea);

					function listener(e) {
						e.clipboardData.setData("text/html", textAreaFormatted.trim());
						e.clipboardData.setData("text/plain", textAreaFormatted.trim());
						e.preventDefault();
					}
			
					document.addEventListener("copy", listener);
					document.execCommand("copy");
					document.removeEventListener("copy", listener);

					setIsCopy(true);
					setTimeout(() => setIsCopy(false), 1500);
				}

				// MINTED - Send request when COPY button is clicked - Hardcoded for now
				const flowUuid = query.flowId;
				const conversationId = query.ip_conversation_id;
				if (flowUuid === "40b043fd-f625-4516-ae13-604958f12b80") {
					const url = "https://ml.kodif.io/api/v1/agent-assist/event";
					const token =
						"eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIyNSIsImlhdCI6MTY1ODMwMDg0MCwiZXhwIjoxNjYwODY3MjAwfQ.gHZcRGvHg8S4VOfzU85Cn1J1Th_wMpPRmh21pIj_rGOgrZTR3m9iWiWuP_JOS-Sywvk5XYQULknD0Y0MI6fg7w";
					const body = JSON.stringify({
						conversation_id: conversationId,
						message_id: variable.value,
						type: "COPY",
						meta: null,
					});
	
					const response = await axios.post(url, body, {
						headers: {
						"Content-Type": "application/json",
						"Authorization": `Bearer ${token}`,
						},
					});
					console.log(await response.text());
				}

			} catch (e) {
				console.warn(e)
			}
		}
		// TODO: for close button AA
		// else if (action?.actionType === "popup") {
		// 	const hrefIframe = new URL(window.location.href);
		// 	if (_.isEmpty(hrefIframe.searchParams.get('popup'))) {
		// 		hrefIframe.searchParams.append('popup', 'true');
		// 		window.location.href = hrefIframe.toString();
		// 	}
		// }
		else {
			onActionClick();
		}
	};

	return (
		<Button
			onClick={onClickAction}
			style={{
				marginTop: "10px",
				...style.platformStyle.web.style,
				...Utils.styleConverter(commonStyle),
				...disabledStyles
			}}
			variant={style?.platformStyle?.web?.customProps?.variant ?? "default"}
			// color={style.color}
			disabled={disabled}
		>
			{action?.actionType === "link" ? (
				<Link
					target={action.actionData?.target || "_blank"}
					href={action.actionData?.url}
					underline={action.actionData?.underline || "always"}
					style={action.actionData?.style || {}}
				>
					{data.title}
				</Link>
			) : (
				isCopy ? 'Copied' : data.title
			)}
		</Button>
	);
};
export default FlexButton;
