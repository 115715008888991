import { Typography } from "@material-ui/core";
import parse from 'html-react-parser';
import * as Utils from "../../utils/utils";

const FlexText = ({ view, userInputs }) => {
  const { data, userInput } = view;
  const style = view.style?.platformStyle?.web?.style || {};
  const commonStyle = view.style?.commonStyle || {};
  let currValue = data.value;
  if (userInput) {
    if (userInputs[view.userInput]) {
      currValue = currValue.replace(view.userInput, userInputs[view.userInput]);
    } else {
      currValue = currValue.replace(view.userInput, `{{${view.userInput}}}`);
    }
    if (currValue === undefined) {
      currValue = `{{${view.userInput}}}`;
    }
  }

  return (
    <Typography component="div" style={{ ...Utils.styleConverter(commonStyle), ...style }}>
      {parse(currValue)}
    </Typography>
  );
};

export default FlexText;
