import React, {useState, useEffect} from "react";
import {withRouter} from "react-router";
import {useHistory} from "react-router-dom";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {createTheme, ThemeProvider} from "@material-ui/core/styles";
import _ from "lodash";
import {Helmet} from "react-helmet-async";
import {v4 as uuidv4} from "uuid";
import {GET_API_URL} from "../constant";
import Views from "./components/Views";

const sessionType = "SESSION_EXPIRED";

const SelfService = ({
  match,
  initialParams,
  env,
  correlationId,
  flowUuid,
  flowVersionUuid,
  setError,
  setLoading,
  isLoading,
  setSessiontimeout,
  setState,
  flowNewSession,
  /** ui-builder-demo **/ ubd_demoInfo
}) => {
  /** ui-builder-demo **/ const {
    ubd_nodeId,
    ubd_token,
    ubd_fbession,
    ubd_mode
  } = ubd_demoInfo;
  const history = useHistory();
  const [sessionId, setSessionId] = useState(!_.isEmpty(flowNewSession) ? flowNewSession : uuidv4());
  const [nodeUuid, setNodeUuid] = useState();
  const [onLoadFont, setOnLoadFont] = useState("");
  const [viewData, setViewData] = useState({
    config: {},
    variables: [],
    views: [],
    userInputs: {}
  });
  const [openAlert, setOpenAlert] = useState({
    show: false,
    variant: "warning",
    vertical: "top",
    horizontal: "center",
    message: ""
  });

  /** ui-builder-demo **/ const [ubd_demoData, setDemoData] = useState(false);
  /** ui-builder-demo **/ const getDemoNode = async () => {
    try {
      const url = `${GET_API_URL(
        env
      )}crud/node/${ubd_nodeId}/fire-base-session/${ubd_fbession}/generate-node-manifest`;
      const headers = {
        authorization: `Bearer ${ubd_token}`
      };
      const res = await axios.get(url, headers);
      const parsed = JSON.parse(res?.data?.payload);
      setDemoData(parsed);
      return {success: true, fail: false, data: parsed};
    } catch (e) {
      setDemoData(false);
      return {success: false, fail: true, ...(e?.response?.data || e)};
    }
  };

  function resetSessionId () {
    const newSessId = uuidv4();
    setSessionId(newSessId);
    const hrefIframe = new URL(window.location.href);
    hrefIframe.searchParams.set('session', newSessId);
    window.location.href = hrefIframe.toString();
  }

  const handleClickAlert = (message = "", variant = "warning") => {
    setOpenAlert({
      ...openAlert,
      variant,
      message,
      show: true
    });
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert({
      ...openAlert,
      show: false,
      message: ""
    });
  };

  const checkIfFormIsValid = () => {
    const requiredKeys = viewData.variables.reduce((keys, variable) => {
      if (variable.required === true) {
        keys.push(variable.name);
      }
      return keys;
    }, []);
    let isValid = true;
    requiredKeys.forEach(inputKey => {
      const newUserInputs = {};
      for (let key in viewData.userInputs) {
        let newKey = key.split(".");
        let lastItem = newKey[newKey.length - 1];
        newUserInputs[lastItem] =
          newUserInputs[lastItem] || viewData.userInputs[key];
      }
      if (
        !newUserInputs.hasOwnProperty(inputKey) ||
        newUserInputs[inputKey] == null ||
        newUserInputs[inputKey] === ""
      ) {
        isValid = false;
      }
    });
    return isValid;
  };

  //
  // executeData
  //

  const executeData = (userInputs = viewData.userInputs, retry = 1) => {

    if (!_.isEmpty(userInputs?.selectedItems)) {
      delete userInputs['selectedItems'];
    }
    if (!_.isEmpty(userInputs?.items)) {
      delete userInputs['items'];
    }

    const url = `${GET_API_URL(env)}self-serve/execute`;
    setLoading(true);
    axios({
      url,
      headers: {
        "Content-Type": "application/json",
        "X-Trace-Id": correlationId,
      },
      method: "POST",
      data: {
        session: sessionId,
        userInputs,
        flowUuid,
        flowVersionUuid,
        initialParams
      }
    })
      .then(res => {
        if (res.data.errorType === sessionType) {
          setSessiontimeout(true);
        }
        if (!res.data.success) {
          const errorMessage =
            "undefined:6: TypeError: Cannot read property 'length' of null";
          const emailErrorMessage =
            "Path '$.orders[0].FIRST_NAME' doesn't exist in the context";
          if (
            res.data.errorDetails === errorMessage ||
            res.data.errorDetails === emailErrorMessage
          ) {
            setState(prev => ({
              ...prev,
              isLoggedIn: false,
              errorMessage: res.data.errorDetails
            }));
          }
        }
        const {lineage} = res.data;
        if (res.data.success) {
          setNodeUuid(lineage[lineage.length - 1].nodeUuid);
          const commonManifest = res.data.manifests.find(
            m => m.type === "COMMON"
          );
          if (!commonManifest || !commonManifest.json) {
            return;
          }
          const data =
            commonManifest.json !== null
              ? JSON.parse(commonManifest.json)
              : null;
          if (data) {
            setViewData(prev => ({
              ...prev,
              ...data,
              userInputs: initializeDefaultUserInputs(
                data?.views,
                data?.variables
              )
            }));
          }
          try {
            const context = JSON.parse(res.data.context);
            const contextConfig = JSON.parse(context.FLOW_CONFIG);
            const globalFontFamily = contextConfig?.theme?.fontFamily;
            setOnLoadFont(globalFontFamily);
          } catch (e) {
            console.log("e", e);
          }
        } else {
          if (res.data.clientFacingErrorMessage !== null) {
            handleClickAlert(res.data.clientFacingErrorMessage);
          }
        }
        setLoading(false);
      })
      .catch(err => {
        if (err?.response?.status === 500) {
          if (retry < 3) {
            executeData(userInputs, retry + 1);
          } else {
            setLoading(false);
            setError({
              errorMessage: err.response.data.errorMessage,
              hasError: true
            });
          }
        }
        throw err;
      });
  };

  const executeGoBack = () => {
    setLoading(true);
    const url = `${GET_API_URL(env)}self-serve/goback`;
    axios({
      url,
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      data: {
        session: sessionId,
        flowUuid
      }
    }).then(res => {
      if (res.data.errorType === sessionType) {
        setSessiontimeout(true);
      }
      if (res.data.success) {
        const commonManifest = res.data.manifests.find(
          m => m.type === "COMMON"
        );
        if (!commonManifest || !commonManifest.json) {
          return;
        }
        const data = JSON.parse(commonManifest.json);
        setLoading(false);
        setViewData(prev => ({
          ...prev,
          ...data,
          userInputs: initializeDefaultUserInputs(data.views, data.variables)
        }));
      }
    });
  };

  const initializeDefaultUserInputs = (views, variables) => {
    const userInputs = {};
    // get default values from variables
    if (variables && variables.length) {
      variables.forEach(v => {
        if (v.defaultValue) {
          userInputs[v.name] = v.defaultValue;
        }
      });
    }
    return views?.reduce((result, view) => {
      if (view.variable?.key != null) {
        if (view.variable.defaultValue) {
          result[view.variable.key] = view.variable.defaultValue;
        }
        switch (view.type) {
          case "radio":
            if (!result.hasOwnProperty(view.variable.key)) {
              result[view.variable.key] = null;
            }
            if (view.data.checked === true) {
              result[view.variable.key] = view.variable.value;
            }
            break;
          //add a new case for a different type of field to define it's default value here
          default:
            break;
        }
      }
      return result;
    }, userInputs);
  };

  const checkActionType = (type = '') => {
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("actionType", type);
      const newRelativePathQuery = window.location.pathname + '?' + searchParams.toString();
      history.push(newRelativePathQuery);
    }
  };

  const onActionClick = view => {
    // hardcoded for textfiled type submit
    const {key = null, value = null} =
      view.type === "textfield"
        ? view?.action?.variable || {}
        : view.variable ?? {};

    switch (view?.action?.actionType) {
      case "submit":
        const userInputs = {...viewData.userInputs};
        if (key !== null && value !== null && value !== "") {
          userInputs[key] = value;
          setViewData(prev => ({...prev, userInputs}));
        }
        executeData(userInputs);
        break;
      case "userInput":
        onUserInputFieldChange(key, value);
        break;
      case "goBack":
        executeGoBack();
        break;
      case "reset":
        if (!_.isEmpty(flowNewSession)) {
          resetSessionId();
        } else window.location.reload();
        break;
      case "close":
        if (!_.isEmpty(flowNewSession)) {
          resetSessionId();
        } else window.location.reload();
        break;
      default:
        break;
    }
  };

  const onUserInputFieldChange = (key, value, type = '') => {
    setViewData(prev => {
      let userInputs = {};
      if (value === 'unchecked') {
        if (prev.userInputs.hasOwnProperty(key)) {
          delete prev.userInputs[key]
        }
        userInputs = { ...prev.userInputs };
        return {
          ...prev,
          userInputs
        };
      }

      if (type === 'radio' && key.includes('.') && !_.isEmpty(prev.userInputs)) {
        delete prev.userInputs['selectedItems'];
        const oldKeyItem = Object.keys(prev.userInputs).filter(prevItem => prevItem.includes('.'))[0];
        if (!_.isEmpty(oldKeyItem)) {
          delete prev.userInputs[oldKeyItem];
        }
      }
      if (type === 'checkbox' && key.includes('.') && !_.isEmpty(prev.userInputs)) {
        delete prev.userInputs['items'];
      }

      userInputs = { ...prev.userInputs, [key]: value };

      return {
        ...prev,
        userInputs
      };
    });
  };

  useEffect(() => {
    if (ubd_demoInfo && ubd_mode === "preview") {
      getDemoNode();
    }
  }, []);

  useEffect(() => {
    executeData();
  }, [match.params.flowId]);

  const defaultMaterialTheme = createTheme({
    typography: {
      fontFamily: `${_.size(onLoadFont) > 0 ? onLoadFont : 'inherit'}`
    },
    button: {
      fontFamily: `${_.size(onLoadFont) > 0 ? onLoadFont : 'inherit'}`
    }
  });

  const includesFontApi = () => {
    if (onLoadFont?.length > 0) {
      return (
        <link
          href={`https://fonts.googleapis.com/css2?family=${_.replace(
            onLoadFont,
            " ",
            "+"
          )}&display=swap`}
          rel="stylesheet"
        />
      );
    }
    return null;
  };

  const isValid = checkIfFormIsValid();
  return (
    !isLoading && (
      <>
        <Helmet
          style={[
            {
              cssText: `
						  html {
							  font-family: "${onLoadFont?.length > 0 ? onLoadFont : "inherit"}";
						  }
					  `
            }
          ]}
        >
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          {includesFontApi()}
        </Helmet>
        <Snackbar
          open={openAlert.show}
          autoHideDuration={4000}
          onClose={handleCloseAlert}
          anchorOrigin={{
            vertical: openAlert.vertical,
            horizontal: openAlert.horizontal
          }}
        >
          <Alert
            elevation={3}
            onClose={handleCloseAlert}
            severity={openAlert.variant}
          >
            {openAlert.message}
          </Alert>
        </Snackbar>
        <ThemeProvider theme={defaultMaterialTheme}>
          <Views
            /** ui-builder-demo **/ views={
              ubd_demoData ? ubd_demoData.views : viewData.views
            }
            /** ui-builder-demo **/ onActionClick={
              ubd_demoData ? () => {} : onActionClick
            }
            userInputs={viewData.userInputs}
            onUpdateData={onUserInputFieldChange}
            isValid={isValid}
            nodeUuid={nodeUuid}
            env={env}
            sessionId={sessionId}
          />
        </ThemeProvider>
      </>
    )
  );
};

export default withRouter(SelfService);
