import caretLogo from "../../../assets/img/caret.png";
import avatar from "../../../assets/img/avatar.png";
import orderDelivered from "../../../assets/img/order_delivered.png";
import * as Utils from "../../utils/utils";

const FlexImage = ({ view }) => {
  const { data, config } = view;
  const style = view.style?.platformStyle?.web?.style || {};
  const commonStyle = view.style?.commonStyle || {};

  const webStyle = { ...Utils.styleConverter(commonStyle), ...style };
  let url;
  if (
      data.url === "caretLogo" ||
      data.url === "avatar" ||
      data.url === "back" ||
      data.url === "backWhite" ||
      data.url === "orderDelivered"
  ) {
    if (data.url === "caretLogo") {
      url = "https://web-sdk.kodif.io/img/caret.svg";
    } else if (data.url === "back") {
      url = "https://web-sdk.kodif.io/img/back.svg";
    } else if (data.url === "backWhite") {
      url = "https://web-sdk.kodif.io/img/backWhite.svg";
    } else if (data.url === "avatar") {
      url = "https://web-sdk.kodif.io/img/avatar.png";
    } else if (data.url === "orderDelivered")
      url = "https://web-sdk.kodif.io/img/orderDelivered.svg";
  } else {
    url = data.url;
  }

  if (url && url.length && url.slice(0, 2) === "//") {
    url = "http:" + url;
  }

  if (config?.type === 'video') {
    return <video src={data.url} style={{ ...webStyle }} autoplay="true" loop preload="auto" />
  }

  return <img alt={url} style={{ ...webStyle }} src={url} />;
};

export default FlexImage;
