
const REACT_APP_API_URL_STAGING = "https://api-staging.kodif.io";
const API_URL = process.env.REACT_APP_API_URL + "/api/v1/";

export const GET_API_URL = env => env === "staging" ? REACT_APP_API_URL_STAGING + "/api/v1/" : API_URL;

// This is just for no-zendesk. we already use real data on zendesk.
export const HARDCODED_COMPANY_PREFIX = "goodeggs";
export const BYTEAPP_COMPANY_PREFIX = "thebyteapp";
export const KODIF_COMPANY_PREFIX = "d3v-kodifhelp";
export const ABELCO_FLOW_ID = "e87a22c7-03e6-48d9-ab1e-c0f39a3f1441";
export const EAZE_FLOW_ID = "d6f13c03-c39a-4f02-a015-c384cedec45f";


// time session to close the app if user does nothing. We should get from the flow later
export const SESSION_TIME = 1800;

export const ROLLBAR_ACCESS_TOKEN = 'cecedc55dcab4cd8a5af5f14aaaeacfd';
