import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { makeStyles } from "@material-ui/core/styles";
import * as Utils from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
  flexList: {
    flexGrow: 1,
  },
}));

const FlexList = ({ view, onActionClick }) => {
  const classes = useStyles();
  const { style = {} } = view.style.platformStyle.web;
  const { commonStyle = {} } = view.style;
  return (
    <List component="nav" className={classes.flexList}>
      {view.listItems.map((list, i) => {
        return (
          <ListItem
            style={{ padding: "8px", ...Utils.styleConverter(commonStyle), ...style }}
            key={i}
            onClick={() => onActionClick(list)}
            button
          >
            <ListItemText primary={list.value} />
            <ListItemIcon style={{ minWidth: "20px" }}>
              <ArrowForwardIosIcon />
            </ListItemIcon>
          </ListItem>
        );
      })}
    </List>
  );
};

export default FlexList;
