import * as Utils from "../../utils/utils";

const FlexVideo = ({ view }) => {
  const { data } = view;
  const style = view.style?.platformStyle?.web?.style || {};
  const commonStyle = view.style?.commonStyle || {};
  const webStyle = { ...Utils.styleConverter(commonStyle), ...style };
  return <video src={data.url} style={{ ...webStyle }} autoplay="true" loop preload="auto" />;
};

export default FlexVideo;
