import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import { MenuItem, FormControl, Select  } from '@material-ui/core';
import * as Utils from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    formControl: {
      flex: 1,
      margin: '8px 0',
      minWidth: 120
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  }));

const FlexDropDown = ({ view, index, onUpdateData, userInputs }) => {
  const classes = useStyles();
  const { data, variable } = view;
  const style = view.style?.platformStyle?.web?.style || {};
  const commonStyle = view.style?.commonStyle || {};

  const { dropDownValues = [] } = data;

  useEffect(() => {
      if (userInputs[variable.key]) {
          return onUpdateData(variable.key, userInputs[variable.key]);
      }
      if (variable?.value) {
          return onUpdateData(variable.key, variable?.value);
      }
  }, []);

  return (
    <FormControl fullWidth className={classes.formControl}>
        {/*{data?.placeholder && <InputLabel id="quantity-select">{data.placeholder}</InputLabel>}*/}
        <Select
          labelId="quantity-select"
          id="demo-simple-select"
          value={userInputs[variable.key] || ''}
          onChange={(e) => {
            onUpdateData(variable.key, e.target.value);
          }}
          style={{ ...Utils.styleConverter(commonStyle), ...style }}
        >
          {
              dropDownValues.map(item => (
                <MenuItem value={item.value} key={item.key}>{item.value}</MenuItem>
              ))
          }
        </Select>
      </FormControl>
  );
};

export default FlexDropDown;
