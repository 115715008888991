import {useEffect, useState} from "react";
import axios from "axios";
import {green, orange, red, yellow} from '@material-ui/core/colors';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Rating from '@material-ui/lab/Rating';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import { checkIconsSize, checkStyleSizeIcons, ConvertSVG } from "../../utils/utils";

import * as Utils from "../../utils/utils";
import { GET_API_URL } from '../../../constant';

const styles = {
  colorDefault: {
    color: 'rgba(0, 0, 0, 0.26)'
  },
  color1: {
    color: red[600]
  },
  color2: {
    color: orange[500]
  },
  color3: {
    color: yellow[700]
  },
  color4: {
    color: orange[200]
  },
  color5: {
    color: green[500]
  },
};

const useStyles = makeStyles(() => ({
  iconHover: styles.colorDefault,
  iconHover1: {
    "& svg": {
      fill: styles.color1.color
    }
  },
  iconHover2: {
    "& svg": {
      fill: styles.color2.color
    }
  },
  iconHover3: {
    "& svg": {
      fill: styles.color3.color
    }
  },
  iconHover4: {
    "& svg": {
      fill: styles.color4.color
    }
  },
  iconHover5: {
    "& svg": {
      fill: styles.color5.color
    }
  },
}));

const FlexRating3 = ({ view, onUpdateData, env, nodeUuid, sessionId, userInputs }) => {
  const classes = useStyles();
  const { style = {}, variable, data, config } = view;
  const { value, type } = data;
  const { commonStyle = {} } = style;
  const checkValue = (valueData) => valueData !== null ? Number(valueData) : null;
  const [valueRating, setValueRating] = useState(checkValue(value));
  const [feedbackText, setFeedbackText] = useState('');

  useEffect(() => {
    let userInput = {
      ratingType: type,
      value: valueRating,
      type: "RATING",
      feedback: feedbackText
    };
    onUpdateData(view.variable.key, userInput);
  }, [valueRating, feedbackText]);

  useEffect(() => {
    if (userInputs?.feedbackRating) {
      setFeedbackText(userInputs?.feedbackRating);
    }
  }, [userInputs?.feedbackRating]);

  const handleChange = (e, newValue) => {
    setValueRating(newValue);
    const url = `${GET_API_URL(env)}crud/analytics/rating`;
    axios({
      url,
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      data: {
        nodeUuid,
        sessionId,
        type,
        value: checkValue(newValue),
        feedback: feedbackText
      },
    });
  };

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon
          fontSize={checkIconsSize(style.platformStyle.web.style?.fontSize)}
          style={{
            ...checkStyleSizeIcons(style.platformStyle.web.style?.fontSize),
            ...(valueRating === 1 ? styles.color1 : styles.colorDefault),
          }}
      />,
      label: 'Bad',
    },
    2: {
      icon: <SentimentSatisfiedIcon
          fontSize={checkIconsSize(style.platformStyle.web.style?.fontSize)}
          style={{
            ...checkStyleSizeIcons(style.platformStyle.web.style?.fontSize),
            ...(valueRating === 2 ? styles.color3 : styles.colorDefault),
          }}
      />,
      label: 'Okay',
    },
    3: {
      icon: <SentimentSatisfiedAltIcon
          fontSize={checkIconsSize(style.platformStyle.web.style?.fontSize)}
          style={{
            ...checkStyleSizeIcons(style.platformStyle.web.style?.fontSize),
            ...(valueRating === 3 ? styles.color5 : styles.colorDefault),
          }}
      />,
      label: 'Great',
    }
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  return (
    <div style={{ ...Utils.styleConverter(commonStyle), ...style.platformStyle.web.style }}>
      <Rating
          max={3}
          value={checkValue(userInputs[variable.key]?.value)}
          name="customized-icons"
          IconContainerComponent={IconContainer}
          onChange={handleChange}
      />
    </div>
  );
};

export default FlexRating3;
