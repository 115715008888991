import { Checkbox, FormGroup, FormControlLabel } from "@material-ui/core";
import { useEffect } from "react";
import _ from 'lodash';
import * as Utils from "../../utils/utils";

const FlexCheckbox = ({ view, value, onUpdateData, userInputs }) => {
  const { data, variable, config } = view;
  const style = view.style?.platformStyle?.web?.style || {};
  const commonStyle = view.style?.commonStyle || {};

  let labelStyle = {
    width: "100%", alignSelf: "flex-start", justifyContent: "space-between", margin: "5px 0", lineBreak: "auto"
  };

  if (!_.isEmpty(config?.labelStyle)) {
    labelStyle = config?.labelStyle
  }

  const onChange = (event) => {
    if (!data.isDisabled) {
      if (event.target.checked) {
        onUpdateData(variable.key, variable.value, view.type);
      } else {
        onUpdateData(variable.key, 'unchecked', view.type); // null
      }
    }
  };

  // goBack function
  useEffect(() => {
    if (!_.isEmpty(userInputs.items)) {
      try {
        const { selectedOrderItems } = JSON.parse(userInputs?.items)[0];
        const selectedValue = `items[${selectedOrderItems}].selectedOrderItems`;
        if (variable.key === selectedValue) {
          return onUpdateData(variable.key, selectedOrderItems);
        }
      } catch (e) {
        console.log(e)
      }
    }
  }, []);

  return (
    <FormGroup style={{ ...Utils.styleConverter(commonStyle), ...style }}>
      <FormControlLabel
        control={<Checkbox onChange={onChange} name="checkbox-btn" color="default" size="medium" disabled={data?.isDisabled} checked={Boolean(userInputs[variable.key]) || false} />}
        label={data.title}
        labelPlacement={config?.labelPlacement || 'start'}
        style={labelStyle}
      />
    </FormGroup>
  );
};

export default FlexCheckbox;
