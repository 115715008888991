import { useState, useEffect } from "react";
import Flows from "./components/Flows";
import { GET_API_URL } from "../constant";

const FlowsViewContainer = ({ setCompanyId, env, onFlowClick, companyDomain, setLoading, isLoading }) => {
  const [flows, setFlows] = useState([]);
  const fetchFlows = () => {
    const activeVersionStatus = 'LIVE';
    const includeFallbackFlows = 'false';
    const includeInactive = 'false';
    const flowsUrl = `${GET_API_URL(env)}zendesk-prefix/${companyDomain}/flows-with-versions?includeInactive=${includeInactive}&includeFallbackFlows=${includeFallbackFlows}&activeVersionStatus=${activeVersionStatus}`;
    fetch(flowsUrl)
    .then((a) => a.json())
    .then((res) => {
      const flows = res?.payload?.flows;
      const companyId = res?.payload?.companyId;
      setCompanyId(companyId);
      setFlows(flows);
    })
    .finally(() => {
      setLoading(false)
    });
  };

  useEffect(() => {
    if (companyDomain) {
      fetchFlows();
    }
  }, []);

  if (!companyDomain) {
    return <p style={{ margin: '20px' }} >Please provide required url parameters</p>;
  }

  return !isLoading && <Flows onFlowClick={onFlowClick} flows={flows} />;
};

export default FlowsViewContainer;
