import FlexText from "./FlexText";
import FlexImage from "./FlexImage";
import FlexVideo from "./FlexVideo";
import FlexButton from "./FlexButton";
import FlexTextField from "./FlexTextField";
import FlexProblemList from "./FlexProblemList";
import FlexAccordion from "./FlexAccordion";
import FlexTextArea from "./FlexTextArea";
import FlexRating from "./FlexRating";
import FlexRating3 from "./FlexRating3";
import FlexRating5 from "./FlexRating5";
import FlexThumbs from "./FlexThumbs";
import FlexList from "./FlexList";
import FlexContainer from "./FlexContainer";
import FlexDatePicker from "./FlexDatePicker";
import FlexDateTimePicker from "./FlexDateTimePicker";
import FlexRadio from "./FlexRadio";
import FlexCheckbox from "./FlexCheckbox";
import Divider from "./FlexDivider";
import FlexDropDown from "./FlexDropDown";
import FlexFileUpload from "./FlexFileUpload";

const FlexComponent = props => {
	const {
		view,
		userInputs,
		index,
		onActionClick,
		onUpdateData,
		isValid
	} = props;
	switch (view.type) {
		case "textfield":
			return (
				<FlexTextField
					view={view}
					userInputs={userInputs}
					index={index}
					onUpdateData={onUpdateData}
					onActionClick={e => onActionClick(view, e)}
				/>
			);
		case "time":
			return (
				<FlexDatePicker
					view={view}
					userInputs={userInputs}
					onUpdateData={onUpdateData}
				/>
			);
		case "date":
			return (
				<FlexDateTimePicker
					view={view}
					userInputs={userInputs}
					onUpdateData={onUpdateData}
				/>
			);
		case "image":
			return <FlexImage view={view} />;
		case "video":
			return <FlexVideo view={view} />;
		case "button":
			return (
				<FlexButton
					view={view}
					index={index}
					onActionClick={e => onActionClick(view, e)}
					isValid={isValid}
				/>
			);
		case "text":
			return <FlexText view={view} index={index} userInputs={userInputs} onUpdateData={onUpdateData} />;
		case "divider":
			return <Divider />;
		case "problemList":
			return (
				<FlexProblemList
					view={view}
					userInputs={userInputs}
					index={index}
					onUpdateData={onUpdateData}
				/>
			);
		case "accordion":
			return (
				<FlexAccordion view={view} index={index} userInputs={userInputs} onUpdateData={onUpdateData} />
			);
		case "textarea":
			return <FlexTextArea view={view} userInputs={userInputs} onUpdateData={onUpdateData} />;
		case "rating":
			if (props.view.data?.type === 'THUMBS_UP_DOWN') {
				return <FlexThumbs {...props} />;
			}
			if (props.view.data?.type === 'CSAT_3_SMILEY') {
				return <FlexRating3 {...props} />;
			}
			if (props.view.data?.type === 'CSAT_5_SMILEY') {
				return <FlexRating5 {...props} />;
			}
			return <FlexRating {...props} />;
		case "listview":
			return (
				<FlexList view={view} onActionClick={e => onActionClick(view, e)} />
			);
		case "container":
			return <FlexContainer onActionClick={onActionClick} {...props} />;
		case "fileupload":
			return <FlexFileUpload {...props} />;
		//radio will be deprecated once the backend config will switch to the new FlexRadioGroup
		case "radio":
			return (
				<FlexRadio
					view={view}
					userInputs={userInputs}
					onUpdateData={onUpdateData}
					value={userInputs[view.variable?.key]}
				/>
			);
		case "checkbox":
			return (
				<FlexCheckbox
					view={view}
					userInputs={userInputs}
					onUpdateData={onUpdateData}
					value={userInputs[view.variable?.key]}
				/>
			);
		case "dropdown":
			return (
				<FlexDropDown {...props} />
			);
		default:
			return <>FIELD {view.type} is not supported</>;
	}
};

export default FlexComponent;
