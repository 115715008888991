import { useState } from "react";
import { Container } from "@material-ui/core";
import FlexComponent from ".";
import * as Utils from "../../utils/utils";

const FlexContainer = ({
	view,
	userInputs,
	index,
	onActionClick,
	onUpdateData,
	keyPrefix,
	isValid,
	nodeUuid,
	sessionId
}) => {
	const { children } = view;
	const style = view.style?.platformStyle?.web?.style || {};
	const actions = view.style?.platformStyle?.web?.actions || {};
	const cactions = view.style?.commonAction || {};
	const commonStyle = view.style?.commonStyle || {};
	const isClickable =
		view.action?.actionType === "submit" ||
		view.action?.actionType === "userInput";
	if (isClickable) {
		style.cursor = "pointer";
	}
	const [hover, setHover] = useState(false);
	const setPointer = view.action?.actionType && "pointer";
	return (
		<Container
			onClick={e => {
				view.action?.actionType && onActionClick(view, e);
			}}
			onMouseEnter={() => {
				setHover(true);
			}}
			onMouseLeave={() => {
				setHover(false);
			}}
			style={{ ...Utils.styleConverter(commonStyle), ...style }}
			style={{
				...style.normal,
				...(hover && isClickable
					? { ...style, ...cactions.onHover }
					: { ...style }),
				...(setPointer && { cursor: setPointer })
			}}
		>
			{children.map((childView, i) => {
				const key = `${keyPrefix}-${i}`;
				return (
					<FlexComponent
						key={key}
						keyPrefix={key}
						view={childView}
						isValid={isValid}
						userInputs={userInputs}
						index={i}
						onActionClick={onActionClick}
						onUpdateData={onUpdateData}
						nodeUuid={nodeUuid}
						sessionId={sessionId}
					/>
				);
			})}
		</Container>
	);
};

export default FlexContainer;
