import { Fragment } from "react";
import { Typography, FormControl, Select, MenuItem } from "@material-ui/core";

const FlexProblemList = ({ view, index, onUpdateData }) => {
  const updateSelectData = (key, value, view, ind, selectIndex) => {
    view[key][selectIndex].select.value = value;
    onUpdateData(key, view[key], view, ind);
  };
  return (
    <>
      {view.listItems.map((item, selectIndex) => {
        return (
          <Fragment key={selectIndex}>
            <Typography align="left">{item.title}</Typography>
            <FormControl variant="outlined" style={{ minWidth: "250px" }}>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={item.select.value}
                onChange={(e) =>
                  updateSelectData(
                    "listItems",
                    e.target.value,
                    view,
                    index,
                    selectIndex
                  )
                }
                label="hello there from label"
              >
                {item.select.options.map((option, optionIndex) => {
                  return (
                    <MenuItem key={optionIndex} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Fragment>
        );
      })}
    </>
  );
};

export default FlexProblemList;
