import "date-fns";
import React, {useEffect} from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import {
	MuiPickersUtilsProvider,
	KeyboardTimePicker
} from "@material-ui/pickers";
import * as Utils from "../../utils/utils";

const FlexDatePicker = ({ view, onUpdateData, userInputs }) => {
	const [selectedDate, setSelectedDate] = React.useState();

	const { data, style, variable } = view;
	const { commonStyle = {} } = style;

	const convertedTime = (newTime) => {
		const times = newTime;
		const minutes = times.slice(0,2);
		const seconds = times.slice(3,5);
		const newDate = new Date().setHours(minutes,seconds);
		setSelectedDate(new Date(newDate));
		return onUpdateData(variable.key, times);
	};

	useEffect(() => {
		if (userInputs[variable.key]) {
			return convertedTime(userInputs[variable.key]);
		}
		if (data.value) {
			return convertedTime(data.value);
		}
	}, []);

	const handleDateChange = time => {
		setSelectedDate(time);
		const timeStr = `${String(time.getHours()).padStart(2, "0")}:${String(time.getMinutes()).padStart(2, "0")}`;
		onUpdateData(variable.key, timeStr);
	};

	const defaultMaterialTheme = createTheme({
		palette: {
			primary: {
				main: style?.platformStyle?.web?.theme?.color ? style.platformStyle.web.theme.color : '#3f51b5'
			}
		}
	});

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<ThemeProvider theme={defaultMaterialTheme}>
				<KeyboardTimePicker
					margin="normal"
					id="time-picker"
					label={data.label}
					value={selectedDate}
					onChange={handleDateChange}
					KeyboardButtonProps={{
						"aria-label": "change time"
					}}
					style={{
						width: "100%",
						...Utils.styleConverter(commonStyle),
						...style.platformStyle.web.style
					}}
					variant={style?.platformStyle?.web?.customProps?.variant || 'default'}
				/>
			</ThemeProvider>
		</MuiPickersUtilsProvider>
	);
};

export default FlexDatePicker;
