import React, {useEffect, useState} from "react";
import {BrowserRouter, useLocation} from "react-router-dom";
import axios from "axios";
import {makeStyles} from "@material-ui/core/styles";
import {Backdrop, Box, Button, Collapse, IconButton} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { v4 as uuidv4 } from 'uuid';
import _ from "lodash";
import Alert from "@material-ui/lab/Alert";
import {ErrorBoundary, historyContext, Provider as RollBarProvider} from "@rollbar/react";
import Rollbar from "rollbar";
import {Helmet} from "react-helmet-async";
import queryString from 'query-string'
import {createBrowserHistory} from "history";
import "./styles/custom.css";
import AuthContainer from "./auth";
import FlowsViewContainer from "./flows/FlowsView";
import SelfService from "./self-service/SelfService";
import {
  BYTEAPP_COMPANY_PREFIX,
  HARDCODED_COMPANY_PREFIX,
  KODIF_COMPANY_PREFIX,
  ROLLBAR_ACCESS_TOKEN,
  ABELCO_FLOW_ID,
  EAZE_FLOW_ID,
  GET_API_URL
} from "./constant";
import KodifLogo from './assets/img/logo/kodif-new-logo.svg';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1
  },
  backdrop: {
    zIndex: 10000,
    color: "#fff"
  }
}));
let zendeskClient = null;
const zendeskAgentPage = window.ZAFClient;
if (zendeskAgentPage) {
  zendeskClient = window.ZAFClient.init();
  if (zendeskClient.invoke) {
    zendeskClient.invoke("resize", {
      width: "100%",
      //height: "calc(100vh - 315px)",
      height: "600px"
    });
  }
}

// A custom hook that builds on useLocation to parse
// the query string for you.
const useQuery = () => {
  const {search} = useLocation();
  return search;
};

const App = () => {
  const [ setIsByteappLoggedIn] = useState(false);
  const [isDeviceType, setIsDeviceType] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [flowNewSession, setFlowNewSession] = useState(null);

  /** ui-builder-demo **/ const [ubd_demoInfo, setDemoInfo] = useState(false);

  const [state, setState] = useState({
    companyId: null,
    flowUuid: null,
    flowVersionUuid: null,
    emulatorLogs: [],
    correlationId: uuidv4(),
    sessionTimeout: false,
    env: null,
    companyDomain: "",
    initialParams: {
      appPlatform: "web",
      appVersion: "1.00.11",
      origin: "agent",
      email: null,
      requesterId: null
    },
    isLoggedIn: false,
    widthFrame: null,
    error: {hasError: false, errorMessage: ""}
  });
  const initialParamUpdate = obj =>
    setState(prev => ({
      ...prev,
      isLoggedIn: true,
      errorMessage: null,
      initialParams: {...prev.initialParams, ...obj}
    }));
    const query = queryString.parse(useQuery());

  const classes = useStyles();
  let hideKodif = false;
  // hide if it's "kodifhelp zendesk form" but not our mapped form
  if (
    document.location.href.startsWith(
      "https://d3v-kodifhelp.zendesk.com/hc/en-us/requests/new"
    ) &&
    document.location.href !==
      "https://d3v-kodifhelp.zendesk.com/hc/en-us/requests/new?ticket_form_id=1900000025185"
  ) {
    hideKodif = true;
    // hide if it's "nomnomnow zendesk form" but not our mapped form
  } else if (
    document.location.href.startsWith(
      "https://support.nomnomnow.com/hc/en-us/requests/"
    ) &&
    !(
      document.location.href ===
        "https://support.nomnomnow.com/hc/en-us/requests/new?ticket_form_id=5789676201243" ||
        document.location.href ===
        "https://support.nomnomnow.com/hc/en-us/requests/new?ticket_form_id=6936602803611" ||
        document.location.href ===
        "https://support.nomnomnow.com/hc/en-us/requests/new?ticket_form_id=6936630982043" ||
      document.location.href ===
        "https://support.nomnomnow.com/hc/en-us/requests/new?ticket_form_id=6336887358491"
    )
  ) {
    hideKodif = true;
  }

  const env = query.env;
  const e = env === "staging" ? "staging" : "production";
  const rollbarConfig = {
    environment: e,
    accessToken: ROLLBAR_ACCESS_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true
  };
  const rollbar = new Rollbar(rollbarConfig);
  const historyListen = createBrowserHistory();
  historyListen.listen(historyContext(rollbar));

  const renderMainRouter = () => {
    const errorMessage =
      "undefined:6: TypeError: Cannot read property 'length' of null";
    const emailErrorMessage =
      "Path '$.orders[0].FIRST_NAME' doesn't exist in the context";
    /*  const isLoggedInGoodeggs = state.initialParams.email && state.isLoggedIn
    let isLoggedIn = state.companyDomain === BYTEAPP_COMPANY_PREFIX ? isByteappLoggedIn : isLoggedInGoodeggs
 */
    let isLoggedIn = state.isLoggedIn;
    if (state.companyDomain === HARDCODED_COMPANY_PREFIX) {
      isLoggedIn = state.initialParams.email && state.isLoggedIn;
    }
    // hide the kodif login in d3v-kodifhelp.zendsk if the Order Issues is not selected
    if (hideKodif) {
      return null;
    }

    const bgColorFlow = () => {
      let bgColor = "#fff";
      if (
        state.flowUuid === "7f43c8d6-bb6d-429b-9ac1-a420818135e0" ||
        state.flowUuid === "16a7f41a-346b-4b9b-96ef-63749e0b0b8c" ||
        state.flowUuid === "2e0d142f-8cb4-4720-8a61-b55994c88534" ||
        state.flowUuid === "252a2155-5a48-4c90-84ca-8afb4dc62362" ||
        state.flowUuid === "e346f992-4e24-4ac8-b164-734eb873f887" ||
        state.flowUuid === "0732fbc9-a4cf-4049-8495-11f6c296c004" ||
        state.flowUuid === "6b2bc2ac-cb62-496b-9a3d-bb5879a36ba5" ||
        state.flowUuid === "bade493b-f764-4369-aa32-cec1ad2f00d6" ||
        state.flowUuid === "bbc48206-6343-46c9-b315-12b73570791c" ||
        state.flowUuid === "d5c0ce41-6700-4579-9764-58f5e07b9ef3" ||
        state.flowUuid === "41cddebf-a666-439b-9d63-96650c361248"
      ) {
        bgColor = "#F7F5F2";
      }
      // copilot bg
      if(state.initialParams.copilot === "true"){
        return "#172335"
      }
      return bgColor;
    };

    const fontForFlow = () => {
      if (
          (state.flowUuid === ABELCO_FLOW_ID) ||
          (state.flowUuid === '9e66cec9-e324-4bcb-9259-359fa7a6c411') ||
          (state.flowUuid === '4441ff1f-1a06-445f-b404-b3835775f265') ||
          (state.flowUuid === 'cdee222a-8cea-4f32-af66-3927fbebb890')
      ) {
        require('./assets/css/fonts-abel-co.css')
      }
      if (state.flowUuid === EAZE_FLOW_ID) {
        require('./assets/css/fonts-eaze-co.css')
      }
      return null
    };

    return (
      <div className={classes.root}>
        <Helmet
          style={[
            {
              cssText: `
              html {
                  background-color: ${bgColorFlow()};
                  margin: 0 auto;
                  overflow-x: hidden;
                  overflow-y: auto;
              }
          `
            }
          ]}
        >
          {fontForFlow()}
        </Helmet>
        <BrowserRouter>
          {!isLoggedIn && state.companyDomain === BYTEAPP_COMPANY_PREFIX ? (
            <AuthContainer
              setIsByteappLoggedIn={setIsByteappLoggedIn}
              email={state.initialParams.email}
              companyDomain={state.companyDomain}
              intialParamUpdate={initialParamUpdate}
              msg={
                !state.isLoggedIn &&
                (state.errorMessage === errorMessage ||
                  state.errorMessage === emailErrorMessage)
                  ? "There are no orders found for this email in last 3 months. Would you like to try a different email?"
                  : "Enter client's email!!"
              }
            />
          ) : !state.flowUuid ? (
            <FlowsViewContainer
              companyDomain={state.companyDomain}
              env={state.env}
              onFlowClick={flowUuid => setState(prev => ({...prev, flowUuid}))}
              isLoading={isLoading}
              setLoading={setIsLoading}
              setCompanyId={companyId =>
                setState(prev => ({...prev, companyId}))
              }
            />
          ) : (
            <SelfService
              setState={setState}
              /** ui-builder-demo **/ ubd_demoInfo={ubd_demoInfo}
              companyId={state.companyId}
              flowUuid={state.flowUuid}
              flowVersionUuid={state.flowVersionUuid}
              correlationId={state.correlationId}
              env={state.env}
              initialParams={state.initialParams}
              isLoading={isLoading}
              setLoading={setIsLoading}
              setError={error => setState(prev => ({...prev, error}))}
              setSessiontimeout={sessionTimeout =>
                setState(prev => ({...prev, sessionTimeout}))
              }
              flowNewSession={flowNewSession}
            />
          )}
        </BrowserRouter>
      </div>
    );
  };

  const parseInitParams = initParams => {
    const stringParams = queryString.stringify(initParams, { encode: false });
    const regex = /(?:ip_|&ip_|&&ip_)/gi;
    const replaceString = stringParams.replace(regex, '&');
    return queryString.parse(replaceString)
  };

  const getQueries = () => {
    const flowUuid = query.flowId;
    const flowVersionUuid = query.flowVersionUuid;
    const env = query.env;
    const widthFrame = query.width
      ? `${parseInt(query.width)}px`
      : "100%";

    if (!_.isEmpty(query?.session)) {
      setFlowNewSession(query?.session)
    }

    if (query?.actionType) {
      delete query.actionType
    }

    const initialParamsKeys = Object.keys(query).filter(q=>q.startsWith("ip_"));
    let initialParams = initialParamsKeys.reduce((a,b)=>{
      const data = b.replace('ip_', "");
      return {...a, [data]:query[b]}
    },{});

    if (!_.isEmpty(initialParams?.device_type)) {
      setIsDeviceType(true);
    }

    if (query.companyId === '19') {
      if (initialParams?.device_type) {
        initialParams.device_type = "android";
      }
      Object.keys(initialParams).map(initParam => {
        if (initialParams[initParam]?.includes('ip_')) {
          initialParams = parseInitParams(initialParams);
        }
        return null;
      });
    }
    /** ui-builder-demo **/
    const ubd_nodeId = query.nodeId;
    const ubd_token = query.auth;
    const ubd_mode = query.uiBuilder;
    const ubd_fbession = query.session;
    if (ubd_nodeId && ubd_token && ubd_mode) {
      setDemoInfo({ubd_nodeId, ubd_token, ubd_fbession, ubd_mode});
    }
    if (flowUuid && initialParams) {
      setState(prev => ({
        ...prev,
        isLoggedIn: true,
        env,
        flowUuid,
        flowVersionUuid,
        widthFrame,
        initialParams: {...prev.initialParams, ...initialParams}
      }));
    }
  };

  const hideZendeskHCFormFields = (hideAll) => {
    let filedsToHide = document.querySelectorAll(".form-field");
    let footers = document.querySelectorAll("footer");
    for (let i = 1; i < filedsToHide.length; i++) {
      filedsToHide[i].style.display = "none";
    }
    footers[0].style.display = "none";

    // hiding the dropdown which shows forms
    if(hideAll){
      document.querySelector(".request_ticket_form_id").style.display = "none"
    }
  };

  const fetchFlowByUrl = async (flowUrl = '') => {
    const apiUrl = `${GET_API_URL(env)}flow/details-by-url?url=${flowUrl}`;
    const result = await axios(apiUrl);
    return result;
  };

  useEffect(() => {
    if (zendeskAgentPage && zendeskClient.get) {
      zendeskClient.get("ticket").then(({ticket}) => {
        const companyDomain = ticket.brand.subdomain;
        // this hardcoded data for testing for our own domain and has no effect for other companies.
        // we need to flowUuid and companyId to show only 1 flow
        if (companyDomain === KODIF_COMPANY_PREFIX) {
          if (ticket.id === 2244) {
            return setState(prev => ({
              ...prev,
              isLoggedIn: true,
              env: "production",
              companyId: 19,
              flowUuid: "a093763d-d899-4d4a-9237-cd1ec3875292",
              initialParams: {
                ...state.initialParams,
                email: ticket.requester.email,
                ticket_id: ticket.id,
                assignee_group_id: ticket?.assignee?.group?.id || null,
                assignee_user_id: ticket?.assignee?.user?.id || null,
                language: 'en'
              },
              companyDomain: KODIF_COMPANY_PREFIX
            }));
          } else 
          if (ticket.id === 196) {
            return setState(prev => ({
              ...prev,
              isLoggedIn: true,
              env: "production",
              companyId: 8,
              flowUuid: "38117f1f-5a40-4423-922a-1bb9fca59b8d",
              initialParams: {
                ...state.initialParams,
                email: ticket.requester.email,
                ticket_id: ticket.id,
                assignee_group_id: ticket?.assignee?.group?.id || null,
                assignee_user_id: ticket?.assignee?.user?.id || null,
                language: 'en'
              },
              companyDomain: KODIF_COMPANY_PREFIX
            }));
          } else if (ticket.id === 196 || ticket.id === 135) {
            return fetchFlowByUrl(`https://${KODIF_COMPANY_PREFIX}.zendesk.com/${HARDCODED_COMPANY_PREFIX}/agent-assistant/agent-assistant-flow`).then(({ data }) => {
              if (data.success) {
                const { payload: { companyId, flowUuid } } = data;
                return setState(prev => ({
                  ...prev,
                  isLoggedIn: true,
                  env: "production",
                  companyId,
                  flowUuid,
                  initialParams: {
                    ...state.initialParams,
                    email: ticket.requester.email || 'test@kodif.io',
                    ticket_id: ticket.id,
                    requesterId: ticket.requester.id,
                    assignee_group_id: ticket?.assignee?.group?.id || null,
                    assignee_user_id: ticket?.assignee?.user?.id || null
                  },
                  companyDomain: HARDCODED_COMPANY_PREFIX
                }));
              }
              return null
            });
          }
          return fetchFlowByUrl(`https://${KODIF_COMPANY_PREFIX}.zendesk.com/${HARDCODED_COMPANY_PREFIX}/agent-assistant/urbanstems`).then(({ data }) => {
            if (data.success) {
              const { payload: { companyId, flowUuid } } = data;
              return setState(prev => ({
                ...prev,
                isLoggedIn: true,
                env: "production",
                companyId,
                flowUuid,
                initialParams: {
                  ...state.initialParams,
                  email: ticket.requester.email || 'test@kodif.io',
                  ticket_id: ticket.id,
                  requesterId: ticket.requester.id,
                  assignee_group_id: ticket.assignee?.group?.id || null,
                  assignee_user_id: ticket.assignee?.user?.id || null
                },
                companyDomain: HARDCODED_COMPANY_PREFIX
              }));
            }
            return null
          });
        }
        if (companyDomain === HARDCODED_COMPANY_PREFIX) {
          // we need to flowUuid and companyId to show only 1 flow
          return fetchFlowByUrl(`https://${HARDCODED_COMPANY_PREFIX}.zendesk.com/${HARDCODED_COMPANY_PREFIX}/agent-assistant/goodeggs-agent-facing-production`).then(({ data }) => {
            if (data.success) {
              const { payload: { companyId, flowUuid } } = data;
              return setState(prev => ({
                ...prev,
                isLoggedIn: true,
                env: "production",
                companyId,
                flowUuid,
                initialParams: {
                  ...state.initialParams,
                  email: ticket.requester.email || 'test@kodif.io',
                  ticket_id: ticket.id,
                  requesterId: ticket.requester.id,
                  assignee_group_id: ticket?.assignee?.group?.id || null,
                  assignee_user_id: ticket?.assignee?.user?.id || null
                },
                companyDomain: HARDCODED_COMPANY_PREFIX
              }));
            }
          });
        }
        if (companyDomain === BYTEAPP_COMPANY_PREFIX) {
          return setState(prev=>({...prev, initialParams: {...state.initialParams, email:ticket.requester.email, customerId:ticket.requester.id }, companyDomain: BYTEAPP_COMPANY_PREFIX}))
        }
        return setState(prev => ({
          ...prev,
          isLoggedIn: true,
          initialParams: {
            ...state.initialParams,
            email: ticket.requester.email,
            ticket_id: ticket.id,
            requesterId: ticket.requester.id,
            assignee_group_id: ticket?.assignee?.group?.id || null,
            assignee_user_id: ticket?.assignee?.user?.id || null
          },
          companyDomain
        }));
      });
    } else {
      let salesforcePath =
        window.location !== window.parent.location
          ? document.referrer
          : document.location.href;
      // hopper sandbox for kustomerapp chrome plugin
      // https://hopper-sandbox.kustomerapp.com/app/customers/61380e5ad9c06a3563d96480/event/61f07af143ed1717538610da
      if (document.location.host === "hopper-sandbox.kustomerapp.com") {
        return fetchFlowByUrl(`https://hopper-sandbox.kustomerapp.com/hopper/hopper`).then(({ data }) => {
          if (data.success) {
            const { payload: { companyId, flowUuid } } = data;
            return setState(prev => ({
              ...prev,
              isLoggedIn: true,
              env: "production",
              companyId,
              flowUuid
            }));
          }
          return null
        });
      } else if (
        salesforcePath === "https://kodif-dev-ed.lightning.force.com/"
      ) {
        return fetchFlowByUrl(`https://kodif-dev-ed.lightning.force.com/gousto/gousto-salesforce`).then(({ data }) => {
          if (data.success) {
            const { payload: { companyId, flowUuid } } = data;
            return setState(prev => ({
              ...prev,
              isLoggedIn: true,
              env: "production",
              companyId,
              flowUuid,
              companyDomain: "gousto"
            }));
          }
          return null
        });
      } else if (
        document.location.href ===
        "https://d3v-kodifhelp.zendesk.com/hc/en-us/requests/new?ticket_form_id=1900000025185"
      ) {
        // for kodifhelp.zendsk creating a ticket for Order Issues
        hideZendeskHCFormFields();
        return fetchFlowByUrl(`https://${KODIF_COMPANY_PREFIX}.zendesk.com/gousto/agent-assistant/gousto-salesforce`).then(({ data }) => {
          if (data.success) {
            const { payload: { companyId, flowUuid } } = data;
            return setState(prev => ({
              ...prev,
              isLoggedIn: true,
              env: "production",
              companyId,
              flowUuid,
              companyDomain: "gousto"
            }));
          }
          return null
        });
      }
      else if (document.location.host === "d3v-kodifhelp.zendesk.com") {
        if (document.location.pathname === "/hc/en-us/articles/5553714154131") {
          return fetchFlowByUrl(`https://${KODIF_COMPANY_PREFIX}.zendesk.com/reservebar/agent-assistant/wismo`).then(({ data }) => {
            if (data.success) {
              const { payload: { companyId, flowUuid } } = data;
              return setState(prev => ({
                ...prev,
                isLoggedIn: true,
                env: "production",
                companyId,
                flowUuid,
                companyDomain: "reservebar"
              }));
            }
            return null
          });
        }
      }
    }
    getQueries();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(isLoading){
      document.interval = setInterval(() => {
        const apiUrl = `https://gateway.kodif.io/agent-emulator/api/v1/stream-logs?correlationId=${state.correlationId}`;
        axios(apiUrl).then(({ data }) => {
          if(data.success){
            setState(prev => ({
              ...prev,
              emulatorLogs: prev.emulatorLogs.concat(data.payload)
            }));
          }
        }).catch(err=>{
          console.log("err",err)
        })
      }, 2000);
    } else {
      clearInterval(document.interval);
      setState(prev => ({
        ...prev,
        correlationId: uuidv4(),
        emulatorLogs: [],
      }));
    }
    // eslint-disable-next-line
  }, [isLoading]);

  // For reservebar articles
  if (document.location.host === "help.reservebar.com") {
    if (
      document.location.pathname.startsWith("/hc/en-us/articles/5052887131291")
    ) {
      return fetchFlowByUrl(`https://help.reservebar.com/hc/en-us/articles/5052887131291/article/wismo`).then(({ data }) => {
        if (data.success) {
          const { payload: { companyId, flowUuid } } = data;
          return (
            <iframe
              style={{border: "solid 1px #ccc"}}
              title="Web SDK Demo"
              src={`https://web-sdk.kodif.io/?companyId=${companyId}&flowId=${flowUuid}&env=production`}
              width="550"
              height="882"
            />
          );
        }
        return null
      });
    } else if (
      document.location.pathname.startsWith("/hc/en-us/articles/5402164695579")
    ) {
      return fetchFlowByUrl(`https://help.reservebar.com/hc/en-us/articles/5402164695579/article/damaged-orders`).then(({ data }) => {
        if (data.success) {
          const { payload: { companyId, flowUuid } } = data;
          return (
              <iframe
                  style={{border: "solid 1px #ccc"}}
                  title="Web SDK Demo"
                  src={`https://web-sdk.kodif.io/?companyId=${companyId}&flowId=${flowUuid}&env=production`}
                  width="550"
                  height="882"
              />
          );
        }
        return null
      });
    } else if (
      document.location.pathname.startsWith("/hc/en-us/articles/6360112917659")
    ) {
      // Missing Item
      return fetchFlowByUrl(`https://help.reservebar.com/hc/en-us/articles/6360112917659/article/damaged-orders`).then(({ data }) => {
        if (data.success) {
          const { payload: { companyId, flowUuid } } = data;
          return (
              <iframe
                  style={{border: "solid 1px #ccc"}}
                  title="Web SDK Demo"
                  src={`https://web-sdk.kodif.io/?companyId=${companyId}&flowId=${flowUuid}&env=production`}
                  width="550"
                  height="882"
              />
          );
        }
        return null
      });
    } else if (
      document.location.pathname.startsWith("/hc/en-us/articles/5893752613275")
    ) {
      // Receipt Request
      return fetchFlowByUrl(`https://help.reservebar.com/hc/en-us/articles/5893752613275/article/receipt-request`).then(({ data }) => {
        if (data.success) {
          const { payload: { companyId, flowUuid } } = data;
          return (
              <iframe
                  style={{border: "solid 1px #ccc"}}
                  title="Web SDK Demo"
                  src={`https://web-sdk.kodif.io/?companyId=${companyId}&flowId=${flowUuid}&env=production`}
                  width="550"
                  height="882"
              />
          );
        }
        return null
      });
    } else if (
      document.location.pathname.startsWith("/hc/en-us/requests/new")
    ) {
      // Reservebar Generic flow
      // hide forms
      hideZendeskHCFormFields(true);
      return fetchFlowByUrl(`https://help.reservebar.com/hc/en-us/requests/new/article/generic`).then(({ data }) => {
        if (data.success) {
          const { payload: { companyId, flowUuid } } = data;
          return (
              <iframe
                  style={{border: "solid 1px #ccc"}}
                  title="Web SDK Demo"
                  src={`https://web-sdk.kodif.io/?companyId=${companyId}&flowId=${flowUuid}&env=production&ip_generic_flow=true`}
                  width="550"
                  height="882"
              />
          );
        }
        return null
      });
    } else {
      hideKodif = true;
    }
  } else if (
      document.location.href.startsWith("https://support.nomnomnow.com/hc/en-us/requests/new")
    ) {
      // Nomnom Generic flow
      // hide forms
      hideZendeskHCFormFields(true);
      // return fetchFlowByUrl(`https://support.nomnomnow.com/hc/en-us/requests/new/nomnomnow/form/generic-flow`).then(({ data }) => {
      //   if (data.success) {
      //     const { payload: { companyId, flowUuid } } = data;
      //     return (
      //         <iframe
      //             style={{border: "solid 1px #ccc"}}
      //             title="Web SDK"
      //             src={`https://web-sdk.kodif.io/?companyId=${companyId}&flowId=${flowUuid}&env=production`}
      //             width="550"
      //             height="650"
      //         />
      //     );
      //   }
      //   return null
      // });
      return (
        <iframe
          style={{border: "solid 1px #ccc"}}
          title="Web SDK"
          src={`https://web-sdk.kodif.io/?companyId=13&flowId=57b6675d-dbb4-48d7-88ad-49108981731b&env=production`}
          width="550"
          height="650"
        />
      )
    } else if (
      document.location.href.startsWith("https://help.goodeggs.com/hc/en-us/requests/new")
    ) {
      // Goodeggs Web Flow
      // hide forms
      if (document.querySelector("#new_request")) {
        document.querySelector("#new_request").style.display = "none";
      }
      let srcUrl = 'https://web-sdk.kodif.io/?companyId=5&flowId=0abf91f6-0e46-4e1d-85a6-42e2e09270b0&env=production';

      const paramsSearch = queryString.parse(window.location.search);
      if (!_.isEmpty(paramsSearch)) {
        let newUrl = {};
        for (const [key, value] of Object.entries(paramsSearch)) {
          if (key.includes('ip_')) {
            newUrl = {
              ...newUrl,
              [key]: value
            }
          }
        }
        if (!_.isEmpty(newUrl)) {
          const stringSearch = queryString.stringify(newUrl);
          srcUrl = `https://web-sdk.kodif.io/?companyId=5&flowId=0abf91f6-0e46-4e1d-85a6-42e2e09270b0&env=production&${stringSearch}`;
        }
      }

      return (
        <iframe
          style={{border: "solid 1px #ccc"}}
          title="Web SDK"
          src={srcUrl}
          width="550"
          height="650"
        />
      )
    }

  // testing for kodif.
  /* if(document.location.host === "kodifhelp.zendesk.com"){
  if(document.location.pathname.startsWith("/hc/en-us/articles/5553714154131")){
    return (
      <iframe style={{border:"solid 1px #ccc"}} title="Web SDK Demo" src="https://web-sdk.kodif.io?companyId=9&amp;flowId=2e0d142f-8cb4-4720-8a61-b55994c88534&amp;initialParams=%7B%7D&amp;flowVersionUuid=3de2f4a4-94ec-4ac5-8b01-361501f57c80&amp;env=production" width="400" height="882"></iframe>
    )
  }
} */

  const renderFooter = () => {
    if (isDeviceType) return null;
    const hrefIframe = new URL(window.location.href);
    if (!_.isEmpty(hrefIframe.searchParams.get('powered'))) return null;
    return (
        <a href="https://www.kodif.io/"
           target="_blank"
           rel="noreferrer"
           style={{
             display: 'flex',
             color: '#5E6C84',
             fontSize: '10px',
             border: '0.2px solid #8993A4',
             padding: '5.08px 4.55px',
             borderRadius: '2px',
             boxShadow: '0px 1px 2px rgba(94, 108, 132, 0.3)',
             textDecoration: 'none',
             fontFamily: 'Inter, sans-serif',
             width: "120px",
             margin: "20px auto"
           }}
        >
          <img src={`https://web-sdk.kodif.io${KodifLogo}`} alt="logo" loading="lazy" style={{ marginRight: '6px' }} />
          Powered by Kodif
        </a>
    )
  };

  const renderLoading = () => (
    <Backdrop className={classes.backdrop} open={isLoading}>
      {state.emulatorLogs.length ? (
        <>
        <div className="logs">
          {state.emulatorLogs.map(l=><div>✅ {l}<span className="dots"></span></div>)}
        </div>
        <iframe title="Game Emulator" className="game" border={0} src="game/index.html"></iframe>
      </>
      ) :  <div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div>}
    </Backdrop>
  );

  const renderSessiontimeout = () => (
    <Backdrop className={classes.backdrop} open={state.sessionTimeout}>
      <Alert variant="filled" severity="warning">
        Your session is over, please restart app.
        <hr />
        <div style={{display: "flex", justifyContent: "center"}}>
          <Button
            style={{marginTop: "1rem", color: "white", borderColor: "white"}}
            variant="outlined"
            onClick={() => {
              // FOR AA
              const hrefIframe = new URL(window.location.href);
              if (!_.isEmpty(hrefIframe.searchParams.get('session'))) {
                hrefIframe.searchParams.set('session', uuidv4());
                window.location.href = hrefIframe.toString();
              } else window.location.reload();
            }}
          >
            Restart
          </Button>
        </div>
      </Alert>
    </Backdrop>
  );

  const renderError = () => (
    <Box>
      <Collapse in={state.error.hasError}>
        <Alert
          variant="outlined"
          severity={state.error.severity || "error"}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setState(prev => ({...prev, error: {}}));
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {state.error.errorMessage}
        </Alert>
      </Collapse>
    </Box>
  );

  const fallbackError = () => (
    <Box style={{padding: 20}}>
      <Alert variant="outlined" severity={"warning"}>
        Oops! Something went wrong. <br />
        Please try again later
      </Alert>
    </Box>
  );

  // eslint-disable-next-line
  {!isLoading && !hideKodif && renderFooter()}
  return (
    <RollBarProvider instance={rollbar}>
      <ErrorBoundary fallbackUI={fallbackError}>
        <div id="kodif-app">
          {renderError()}
          {renderSessiontimeout()}
          {renderMainRouter()}
          {renderLoading()}
          {!isLoading && !hideKodif && state.initialParams.copilot!=="true" && renderFooter()}
        </div>
      </ErrorBoundary>
    </RollBarProvider>
  );
};
export default App;
