import React, {useState, useEffect} from "react";
import {TextField} from "@material-ui/core";
import * as Utils from "../../utils/utils";

const FlexTextField = ({
  view,
  index,
  onUpdateData,
  onActionClick,
  userInputs
}) => {
  const {data, variable} = view;
  const {customProps = {multiline: true, variant: "standard"}, style = {}} =
    view.style?.platformStyle?.web ?? {};
  const {commonStyle = {}} = view.style;
  const defaultValue = data.value ||  "";

  const [inputValue, setInputValue] = useState(defaultValue);

  useEffect(() => {
    onUpdateData(variable.key, userInputs[variable.key] || defaultValue);
  }, []);

  return (
    <TextField
      className="default-textfield"
      style={{width: "100%", ...Utils.styleConverter(commonStyle), ...style}}
      defaultValue={defaultValue}
      value={inputValue || userInputs[variable.key] || ""}
      type={data.type || "text"}
      InputProps={{
        inputProps: {
          style: {
            width: "100%",
            ...Utils.styleConverter(commonStyle),
            ...style
          }
        }
      }}
      placeholder={data.placeholder}
      onKeyDown={(e)=>{
        if(e.code === "Enter" && e.target.value && e.target.value.trim().length){
          onActionClick();
        }
      }}
      onChange={e => {
        const vals = e.target.value.split("");
        const _vals = vals.filter(v => v !== "\n").join("");
        setInputValue(_vals);
        onUpdateData(variable.key, _vals);
      }}
      {...customProps}
    />
  );
};

export default FlexTextField;
