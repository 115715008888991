import React, { useState, useEffect } from "react";
import { Button, TextField } from "@material-ui/core";
import { BYTEAPP_COMPANY_PREFIX } from "../constant";

const AuthContainer = ({ companyDomain, intialParamUpdate, msg, email, setIsByteappLoggedIn }) => {

  const [emailValue, setEmailValue] = useState('')

  useEffect(() => setEmailValue(email), [email])

  const onFormSubmit = (e) => {
    e.preventDefault();
    let newInitialParams = {
      email: e.target.email.value,
    }
    newInitialParams = e?.target?.orderId?.value ? {...newInitialParams, orderId: e.target.orderId.value} : newInitialParams
    if (newInitialParams.orderId) {
      setIsByteappLoggedIn(true)
    }
    intialParamUpdate(newInitialParams);
  };

  return (
    <form onSubmit={onFormSubmit} style={{ margin: '20px' }} >
      <h3 style={{ marginBottom: "16px", fontSize: "18px" }}>
        {msg}
      </h3>
      {companyDomain !== BYTEAPP_COMPANY_PREFIX && <TextField
        variant="outlined"
        required
        name="email"
        type="email"
        style={{ width: "100%" }}
        label="Email"
      />}
      {companyDomain === BYTEAPP_COMPANY_PREFIX && <TextField
        value={companyDomain === BYTEAPP_COMPANY_PREFIX ? emailValue : ''}
        onChange={(e) => setEmailValue(e.target.value)}
        variant="outlined"
        required
        name="email"
        type="email"
        style={{ width: "100%" }}
        label="Email"
      />}
      {companyDomain === BYTEAPP_COMPANY_PREFIX && <TextField
        variant="outlined"
        required
        name="orderId"
        type="number"
        style={{ width: "100%", marginTop: '10px' }}
        label="Order Id"
      />}
      <Button
        color="primary"
        className="zndsk-btn"
        type="submit"
        variant="outlined"
        style={{ background: "none " }}
      >
        Submit
      </Button>
    </form>
  );
};

export default AuthContainer;
