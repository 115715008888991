import {useEffect, useState} from "react";
import axios from "axios";
import Rating from "@material-ui/lab/Rating";
import * as Utils from "../../utils/utils";
import { GET_API_URL } from '../../../constant';

const FlexRating = ({ view, onUpdateData, env, nodeUuid, sessionId, userInputs }) => {
  const { style = {}, variable, data } = view;
  const { value } = data;
  const { commonStyle = {} } = style;
  const [valueRating, setValueRating] = useState(Number(value) || 0 );
  const feedback = userInputs?.feedbackRating ? userInputs.feedbackRating : '';
  useEffect(() => {
    let userInput = {
      ratingType: "CSAT",
      value: Number(valueRating),
      type: "RATING",
      feedback
    };
    onUpdateData(view.variable.key, userInput);
  }, [valueRating, feedback]);

  const handleChange = (e, newValue) => {
    setValueRating(newValue);
    const url = `${GET_API_URL(env)}crud/analytics/rating`;
    axios({
      url,
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      data: {
        nodeUuid,
        sessionId,
        type: "CSAT",
        value: Number(newValue),
        feedback
      },
    });
  };

  return (
    <Rating
      style={{ ...Utils.styleConverter(commonStyle), ...style.platformStyle.web.style }}
      value={userInputs[variable.key]?.value || 0}
      size="large"
      onChange={(_event, newValue) => handleChange(_event, newValue) }
    />
  );
};

export default FlexRating;
