import _ from "lodash";

export const styleConverter = (obj) => {

    const handleObjType = (prop, propObj) => {
        let updatedObj = {};
        for(const key in propObj){
          let newValue = `${prop}${key[0].toUpperCase()}${key.slice(1)}`
          updatedObj[newValue] = propObj[key]
        }
        return updatedObj
    };

    let newObj = {};
    for(const prop in obj){
      if(typeof obj[prop] !== 'object' && obj[prop] !== null){
        newObj[prop] = obj[prop]
      }else{
        newObj = {...newObj, ...handleObjType(prop, obj[prop])}
      }
    }
    return newObj
};

const checkIconsSize = (fontSize) => {
    let size = 'inherit';
    if (!_.isEmpty(fontSize) && fontSize === 'small' || fontSize === 'medium' || fontSize === 'large') {
        size = fontSize;
        return size;
    }
    if (!_.isEmpty(fontSize) && Number(fontSize)) {
        return size;
    }
    return size;
};

const checkStyleSizeIcons = (fontSize) => {
    let size = {};
    if (!_.isEmpty(fontSize) && Number(fontSize)) {
        return { fontSize };
    }
    return size;
};

const ConvertSVG = ({url = '', size = 24, alt = ''}) => {
    return (
        <img src={url} alt={alt} width={size} />
    )
};

export { checkIconsSize, checkStyleSizeIcons, ConvertSVG }
