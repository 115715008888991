// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/AbelCo/ABELCOLE01.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/AbelCo/montserrat-bold-AC.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/AbelCo/montserrat-light-AC.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/AbelCo/montserrat-regular-AC.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* AbelCo Family Fonts */
@font-face {
    font-family: 'ApplesAndCarrots';
    font-style: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
@font-face {
    font-family: 'ACMontserratBoldFont';
    font-style: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}
@font-face {
    font-family: 'ACMontserratLightFont';
    font-style: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}
@font-face {
    font-family: 'ACMontserratRegularFont';
    font-style: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts-abel-co.css"],"names":[],"mappings":"AAAA,wBAAwB;AACxB;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,kBAAkB;IAClB,+DAA+D;AACnE;AACA;IACI,mCAAmC;IACnC,kBAAkB;IAClB,kBAAkB;IAClB,+DAAuE;AAC3E;AACA;IACI,oCAAoC;IACpC,kBAAkB;IAClB,kBAAkB;IAClB,+DAAwE;AAC5E;AACA;IACI,sCAAsC;IACtC,kBAAkB;IAClB,kBAAkB;IAClB,+DAA0E;AAC9E","sourcesContent":["/* AbelCo Family Fonts */\n@font-face {\n    font-family: 'ApplesAndCarrots';\n    font-style: normal;\n    font-display: swap;\n    src: url('../fonts/AbelCo/ABELCOLE01.woff2') format('truetype');\n}\n@font-face {\n    font-family: 'ACMontserratBoldFont';\n    font-style: normal;\n    font-display: swap;\n    src: url('../fonts/AbelCo/montserrat-bold-AC.woff2') format('truetype');\n}\n@font-face {\n    font-family: 'ACMontserratLightFont';\n    font-style: normal;\n    font-display: swap;\n    src: url('../fonts/AbelCo/montserrat-light-AC.woff2') format('truetype');\n}\n@font-face {\n    font-family: 'ACMontserratRegularFont';\n    font-style: normal;\n    font-display: swap;\n    src: url('../fonts/AbelCo/montserrat-regular-AC.woff2') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
