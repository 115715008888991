import "date-fns";
import React, {useEffect} from "react";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import {
	MuiPickersUtilsProvider,
	DateTimePicker
} from "@material-ui/pickers";
import * as Utils from "../../utils/utils";

const FlexDateTimePicker = ({ view, onUpdateData, userInputs }) => {
	const [selectedDate, setSelectedDate] = React.useState(new Date());

	const { data, style, variable } = view;
	const { commonStyle = {} } = style;

	useEffect(() => {
		if (userInputs[variable.key]) {
			const dateTime = userInputs[variable.key];
			setSelectedDate(new Date(dateTime));
			onUpdateData(variable.key, userInputs[variable.key]);
		}
	}, []);

	const handleDateChange = dateTime => {
		setSelectedDate(dateTime);
		onUpdateData(variable.key, dateTime);
	};

	const defaultMaterialTheme = createTheme({
		palette: {
			primary: {
				main: style?.platformStyle?.web?.theme?.color ? style.platformStyle.web.theme.color : '#3f51b5'
			}
		}
	});

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<ThemeProvider theme={defaultMaterialTheme}>
				<DateTimePicker
					margin="normal"
					id="date-time-picker"
					value={selectedDate}
					label={data.label}
					onChange={handleDateChange}
					hideTabs={true}
					keyboardbuttonprops={{
						"aria-label": "change date-time"
					}}
					style={{
						width: "100%",
						...Utils.styleConverter(commonStyle),
						...style.platformStyle.web.style
					}}
					variant={style?.platformStyle?.web?.customProps?.variant || 'default'}
				/>
			</ThemeProvider>
		</MuiPickersUtilsProvider>
	);
};

export default FlexDateTimePicker;
