import React, {useEffect, useState} from "react";
import {Grid, makeStyles, Box, LinearProgress} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import PublishRoundedIcon from "@material-ui/icons/PublishRounded";
import {useDropzone} from "react-dropzone";
import {v4 as uuidv4} from "uuid";
import {GET_API_URL} from "../../../constant";
import axios from "axios";

const useStyles = makeStyles(theme => ({
  dropzone: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background.default,
    height: theme.spacing(10),
    outline: "none"
  }
}));

const FlexFileUpload = ({nodeUuid, view, onUpdateData, env}) => {
  const classes = useStyles();

  const [succesfulFiles, setSuccesfulFiles] = useState([]);
  const [failedFiles, setFailedFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const makeCalls = (accFiles, mappedAcc, ind) => {
    setLoading(true);
    const url = `${GET_API_URL(env)}upload/user-file`;
    const formData = new FormData();
    formData.append("file", accFiles[ind]);
    axios({
      url,
      headers: {
        "Content-Type": "multipart/form-data"
      },
      method: "POST",
      data: formData
    })
      .then(res => {
        if (res.data.success) {
          if (res.data.payload.url) {
            accFiles[ind].url = res.data.payload.url;
          }
          if (mappedAcc.length > 1) {
            setSuccesfulFiles(curr => [...curr, mappedAcc[ind]]);
          } else {
            setSuccesfulFiles([mappedAcc[ind]]);
          }
          setLoading(false);
        }
      })
      .catch(err => {
        accFiles[
          ind
        ].error = `${err.message}. Please try again or try a different file...`;
        if (mappedAcc.length > 1) {
          setFailedFiles(curr => [...curr, mappedAcc[ind]]);
        } else {
          setFailedFiles([mappedAcc[ind]]);
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    if (succesfulFiles.length) {
      let fileURLs = succesfulFiles.map(file => file.file.url);
      let userInputsValue = {
        type: "UPLOAD_FILES",
        urls: fileURLs
      };
      onUpdateData(view.variable.key, userInputsValue);
    }
  }, [succesfulFiles]);

  const onDrop = (accFiles, rejFiles) => {
    setSuccesfulFiles([]);
    setFailedFiles([]);
    const mappedAcc = accFiles.map(file => ({file, errors: [], id: uuidv4()}));
    if (mappedAcc.length === 1) {
      makeCalls(accFiles, mappedAcc, 0);
    } else if (mappedAcc.length > 1) {
      for (let i = 0; i < mappedAcc.length; i++) {
        makeCalls(accFiles, mappedAcc, i);
      }
    }
  };

  const renderMultipleFileUpload = () => {
    return (
      <Grid style={{marginBottom: "20px"}} item>
        <div
          {...getRootProps({className: classes.dropzone})}
          style={{...(view?.style?.platformStyle?.web?.style || {})}}
        >
          <input {...getInputProps()} />

          {loading && (
            <Box sx={{width: "80%", marginTop: "10px"}}>
              <LinearProgress />
            </Box>
          )}
          {!loading && !succesfulFiles.length && (
            <p>
              {view?.data?.placeholder
                ? view?.data?.placeholder
                : "Drag and drop some files here, or click to select files"}
            </p>
          )}
          {!loading &&
            succesfulFiles.length !== 0 &&
            (succesfulFiles.length === 1 ? (
              <div style={{display: "flex", alignItems: "center"}}>
                <Alert
                  sx={{width: "100%"}}
                  severity="success"
                >{`${succesfulFiles[0].file.name} files`}</Alert>
                <PublishRoundedIcon
                  style={{margin: "15px"}}
                ></PublishRoundedIcon>
              </div>
            ) : (
              <div style={{display: "flex", alignItems: "center"}}>
                <Alert
                  sx={{width: "100%"}}
                  severity="success"
                >{`${succesfulFiles.length} files`}</Alert>
                <PublishRoundedIcon
                  style={{margin: "15px"}}
                ></PublishRoundedIcon>
              </div>
            ))}
        </div>
        <div>
          {failedFiles.length !== 0 &&
            failedFiles.map((file, ind) => {
              return (
                <div key={ind} style={{display: "flex", alignItems: "center"}}>
                  <Alert sx={{width: "100%"}} severity="error">
                    {file.file.name}
                    {" : "}
                    {file.file.error}
                  </Alert>
                </div>
              );
            })}
        </div>
      </Grid>
    );
  };

  const {getRootProps, getInputProps} = useDropzone({onDrop});

  return <>{renderMultipleFileUpload()}</>;
};

export default FlexFileUpload;
