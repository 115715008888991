import {
  Typography,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
  // no classes provided in original Demo
}));

const FlexAccordion = ({ view, index, onUpdateData }) => {
  const classes = useStyles();

  const updateNestedData = (
    key,
    value,
    view,
    ind,
    accordionIndex,
    checkboxIndex
  ) => {
    view[key][accordionIndex].list[checkboxIndex].isChecked = value;
    onUpdateData(key, view[key], view, ind);
  };

  return view.list.map((item, accordionIndex) => {
    return (
      <Accordion key={accordionIndex}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{item.title}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component="fieldset" className={classes.formControl}>
            <FormGroup>
              {item.list.map((checkbox, checkboxIndex) => {
                return (
                  <FormControlLabel
                    key={checkboxIndex}
                    control={
                      <Checkbox
                        checked={checkbox.isChecked}
                        name={checkbox.label}
                        onClick={(e) =>
                          updateNestedData(
                            "list",
                            e.target.checked,
                            view,
                            index,
                            accordionIndex,
                            checkboxIndex
                          )
                        }
                      />
                    }
                    label={checkbox.label}
                  />
                );
              })}
            </FormGroup>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    );
  });
};

export default FlexAccordion;
