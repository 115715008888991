// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../fonts/Eaze/Gilroy-Bold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../fonts/Eaze/Gilroy-ExtraBold.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("../fonts/Eaze/Gilroy-Black.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("../fonts/Eaze/Gilroy-Heavy.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("../fonts/Eaze/Suisse-Intl-Mono.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Eaze Family Fonts */
@font-face {
    font-family: 'Gilroy-Bold';
    font-style: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
}
@font-face {
    font-family: 'Gilroy-ExtraBold';
    font-style: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('truetype');
}
@font-face {
    font-family: 'Gilroy-Black';
    font-style: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_2___}) format('truetype');
}
@font-face {
    font-family: 'Gilroy-Heavy';
    font-style: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_3___}) format('truetype');
}
@font-face {
    font-family: 'Suisse-Intl-Mono';
    font-style: normal;
    font-display: swap;
    src: url(${___CSS_LOADER_URL_REPLACEMENT_4___}) format('truetype');
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/fonts-eaze-co.css"],"names":[],"mappings":"AAAA,sBAAsB;AACtB;IACI,0BAA0B;IAC1B,kBAAkB;IAClB,kBAAkB;IAClB,+DAA4D;AAChE;AACA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,kBAAkB;IAClB,+DAAiE;AACrE;AACA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,+DAA6D;AACjE;AACA;IACI,2BAA2B;IAC3B,kBAAkB;IAClB,kBAAkB;IAClB,+DAA6D;AACjE;AACA;IACI,+BAA+B;IAC/B,kBAAkB;IAClB,kBAAkB;IAClB,+DAAiE;AACrE","sourcesContent":["/* Eaze Family Fonts */\n@font-face {\n    font-family: 'Gilroy-Bold';\n    font-style: normal;\n    font-display: swap;\n    src: url('../fonts/Eaze/Gilroy-Bold.ttf') format('truetype');\n}\n@font-face {\n    font-family: 'Gilroy-ExtraBold';\n    font-style: normal;\n    font-display: swap;\n    src: url('../fonts/Eaze/Gilroy-ExtraBold.ttf') format('truetype');\n}\n@font-face {\n    font-family: 'Gilroy-Black';\n    font-style: normal;\n    font-display: swap;\n    src: url('../fonts/Eaze/Gilroy-Black.ttf') format('truetype');\n}\n@font-face {\n    font-family: 'Gilroy-Heavy';\n    font-style: normal;\n    font-display: swap;\n    src: url('../fonts/Eaze/Gilroy-Heavy.ttf') format('truetype');\n}\n@font-face {\n    font-family: 'Suisse-Intl-Mono';\n    font-style: normal;\n    font-display: swap;\n    src: url('../fonts/Eaze/Suisse-Intl-Mono.ttf') format('truetype');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
