import {useEffect, useState} from "react";
import axios from "axios";
import clsx from 'clsx';
import { green, red } from '@material-ui/core/colors';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import { IconButton } from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

import * as Utils from "../../utils/utils";
import { GET_API_URL } from '../../../constant';
import { checkIconsSize, checkStyleSizeIcons } from "../../utils/utils";

const useStyles = makeStyles(() => ({
  button: {
    "&.dislike": {
      "& svg": {
        fill: red[600]
      },
    },
    "&.like": {
      "& svg": {
        fill: green[600]
      },
    },
  },
}));

const FlexThumbs = ({ view, onUpdateData, env, nodeUuid, sessionId, userInputs }) => {
  const classes = useStyles();
  const { style = {}, variable, data } = view;
  const { value, type } = data;
  const { commonStyle = {} } = style;
  const checkValue = (valueData) => valueData !== null ? Number(valueData) : null;
  const [valueRating, setValueRating] = useState(checkValue(value));
  const [feedbackText, setFeedbackText] = useState('');

  useEffect(() => {
    let userInput = {
      ratingType: type,
      value: valueRating,
      type: "RATING",
      feedback: feedbackText
    };
    onUpdateData(view.variable.key, userInput);
  }, [valueRating, feedbackText]);

  useEffect(() => {
    if (userInputs?.feedbackRating) {
      setFeedbackText(userInputs?.feedbackRating);
    }
  }, [userInputs?.feedbackRating]);

  const handleChange = (newValue) => {
    setValueRating(newValue);
    const url = `${GET_API_URL(env)}crud/analytics/rating`;
    axios({
      url,
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      data: {
        nodeUuid,
        sessionId,
        type: type,
        value: checkValue(newValue),
        feedback: ''
      },
    });
  };

  return (
    <div style={{ ...Utils.styleConverter(commonStyle), ...style.platformStyle.web.style }}>
      <IconButton
          className={clsx(classes.button, valueRating === 0 ? 'dislike' : null )}
          onClick={() => handleChange(0)}>
        <ThumbDownIcon
            fontSize={checkIconsSize(style.platformStyle.web.style?.fontSize)}
            style={{
              ...checkStyleSizeIcons(style.platformStyle.web.style?.fontSize),
            }}
        />
      </IconButton>
      <IconButton
          className={clsx(classes.button,  valueRating === 1 ? 'like' : null )}
          onClick={() => handleChange(1)}>
        <ThumbUpIcon
            fontSize={checkIconsSize(style.platformStyle.web.style?.fontSize)}
            style={{
              ...checkStyleSizeIcons(style.platformStyle.web.style?.fontSize),
            }}
        />
      </IconButton>
    </div>
  );
};

export default FlexThumbs;
