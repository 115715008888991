import { useEffect } from "react";
import { Typography, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from "@material-ui/core";
import * as Utils from "../../utils/utils";

const FlexRadio = ({ view, value, onUpdateData, userInputs }) => {
  const { data, variable } = view;
  const style = view.style?.platformStyle?.web?.style || {};
  const commonStyle = view.style?.commonStyle || {};

    const onChange = (event) => {
        if (!data.isDisabled) {
            if (event.target.value) {
                onUpdateData(variable.key, variable.value, view.type);
            }
        }
    };

    useEffect(() => {
        try {
            if (userInputs?.selectedItems) { // this loopCollection
                const { itemsSelectItem } = JSON.parse(userInputs?.selectedItems)[0];
                const selectedValue = `selectedItems[${itemsSelectItem}].itemsSelectItem`;
                if (variable.key === selectedValue) {
                    return onUpdateData(variable.key, itemsSelectItem);
                }
            }
            if (userInputs[variable.key]) {
                return onUpdateData(variable.key, userInputs[variable.key]);
            }
            if (data?.checked) {
                return onUpdateData(variable.key, data?.checked && variable.value);
            }
        } catch (e) {
            console.warn(e)
        }
    }, []);

  return (
      <FormControl style={{ ...Utils.styleConverter(commonStyle), ...style }}>
          <RadioGroup
              aria-label="radioGroup"
              name="radioGroup"
              // value={variable.value === value}
              onChange={onChange}
              style={{ width: '100%' }}
          >
              <FormControlLabel
                  style={{ width: '100%', margin: 0, justifyContent: 'space-between' }}
                  labelPlacement="start"
                  value={variable.value || userInputs[variable.key]}
                  disabled={data.isDisabled}
                  control={<Radio checked={variable.value === value} size="medium" color="default" />}
                  label={data.title}
              />
          </RadioGroup>
      </FormControl>
  );
};

export default FlexRadio;
