import FlexComponent from "./flex_components";

const Views = ({
  views,
  onActionClick,
  onUpdateData,
  userInputs,
  isValid,
  nodeUuid,
  env,
  sessionId
}) => {
  return views.map((view, i) => {
    const key = `flex-component-${i}`;
    return (
      <FlexComponent
        key={key}
        keyPrefix={key}
        view={view}
        index={i}
        onActionClick={onActionClick}
        onUpdateData={onUpdateData}
        userInputs={userInputs}
        isValid={isValid}
        nodeUuid={nodeUuid}
        env={env}
        sessionId={sessionId}
      />
    );
  })
};

export default Views;
